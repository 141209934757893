import React, { useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { BiPhoneCall, BiTaxi, BiCreditCard } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";
import { TbMail } from "react-icons/tb";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import Container from "./styled";

export default function Canales() {
  // Estado para determinar la tab seleccionada
  const [tab, setTab] = useState("canalUno");
  // Toggle para estado de tab canal uno
  const tabCanalUno = () => {
    setTab("canalUno");
  };
  // Toggle para estado de tab canal dos
  const tabCanalDos = () => {
    setTab("canalDos");
  };
  // Toggle para estado de tab canal tres
  const tabCanalTres = () => {
    setTab("canalTres");
  };

  return (
    <Container
      tabActiva={
        tab == "canalUno"
          ? "canalUno"
          : tab == "canalDos"
          ? "canalDos"
          : "canalTres"
      }
    >
      {/* Section Max Width Container */}
      <section id="max-width-container">
        {/* Tag Container */}
        <Fade delay={100} duration={800} className="tag-container">
          {/* Tag */}
          <div>
            <p>Contacto</p>
          </div>
        </Fade>
        {/* Title Container */}
        <Fade delay={100} duration={800} className="title-container">
          {/* Title */}
          <h3>
            Nuestros <span>canales de comunicación</span>
          </h3>
        </Fade>
        {/* Texto Container */}
        <Fade delay={100} duration={800} className="text-container">
          <p>
            Envianos un WhatsApp o un mail para comunicarte con nosotros y
            sacarte todas tus dudas.
          </p>
        </Fade>
        {/* Tabs Container */}
        <Fade delay={100} duration={800} className="tabs-container">
          {/* Tabs */}
          <div id="tabs">
            {/* Tabs */}
            <a
              onClick={tabCanalUno}
              className={tab == "canalUno" && "tab-activa"}
            >
              <span>
                <BiPhoneCall />
              </span>
              Atención al cliente
            </a>
            <a
              onClick={tabCanalDos}
              className={tab == "canalDos" && "tab-activa"}
            >
              <span>
                <BiTaxi />
              </span>
              Siniestros
            </a>
            <a
              onClick={tabCanalTres}
              className={tab == "canalTres" && "tab-activa"}
            >
              <span>
                <BiCreditCard />
              </span>
              Cobranzas
            </a>
          </div>
        </Fade>
        {/* Switch Transition */}
        <Fade delay={100} duration={800} className="switch-container">
          <SwitchTransition mode={"out-in"}>
            <CSSTransition
              key={
                tab == "canalUno"
                  ? "canalUno"
                  : tab == "canalDos"
                  ? "canalDos"
                  : "canalTres"
              }
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
              classNames="fade"
              unmountOnExit
            >
              {/* Consultas Container */}
              <div id="data-container">
                {tab == "canalUno" ? (
                  <div className="canal">
                    {/* Botón WhatsApp */}
                    <a
                      id="boton-wpp"
                      href="https://api.whatsapp.com/send?phone=541162097006&text=%C2%A1Hola,%20necesito%20atención%20de%20SeguroAuto!%20Ingresé%20desde%20la%20web."
                      target="_blank"
                    >
                      <span>
                        <BsWhatsapp />
                      </span>
                      11-6209-7006
                    </a>
                    <p>|</p>
                    {/* Botón Mail */}
                    <a id="boton-mail" href="mailto:emision@seguroweb.com.ar">
                      <span>
                        <TbMail />
                      </span>
                      emision@seguroweb.com.ar
                    </a>
                  </div>
                ) : tab == "canalDos" ? (
                  <div className="canal">
                    {/* Botón WhatsApp 1 */}
                    <a
                      id="wppCanal3Frist"
                      href="https://api.whatsapp.com/send?phone=541126698544&text=%C2%A1Hola,%20necesito%20atención%20de%20siniestros%20de%20SeguroAuto!%20Ingresé%20desde%20la%20web."
                      target="_blank"
                    >
                      <span>
                        <BsWhatsapp />
                      </span>
                      11-2669-8544
                    </a>
                    <p>|</p>
                    {/* Botón Mail */}
                    <a
                      id="boton-mail"
                      href="mailto:siniestros@seguroweb.com.ar"
                    >
                      <span>
                        <TbMail />
                      </span>
                      siniestros@seguroweb.com.ar
                    </a>
                  </div>
                ) : (
                  <div className="canal">
                    <a
                      id="wppCanal3Frist"
                      href="https://api.whatsapp.com/send?phone=541138307096&text=%C2%A1Hola,%20necesito%20atención%20de%20cobranzas%20de%20SeguroAuto!%20Ingresé%20desde%20la%20web"
                      target="_blank"
                    >
                      <span>
                        <BsWhatsapp />
                      </span>
                      11-3830-7096
                    </a>
                    <p>|</p>
                    <a
                      href="https://api.whatsapp.com/send?phone=541162096970&text=%C2%A1Hola,%20necesito%20atención%20de%20cobranzas%20de%20SeguroAuto!%20Ingresé%20desde%20la%20web"
                      target="_blank"
                    >
                      <span>
                        <BsWhatsapp />
                      </span>
                      11-6209-6970
                    </a>
                    <p>|</p>
                    <a
                      href="https://api.whatsapp.com/send?phone=541166573432&text=%C2%A1Hola,%20necesito%20atención%20de%20cobranzas%20de%20SeguroAuto!%20Ingresé%20desde%20la%20web"
                      target="_blank"
                    >
                      <span>
                        <BsWhatsapp />
                      </span>
                      11-6657-3432
                    </a>
                    <p>|</p>
                    <a
                      href="https://api.whatsapp.com/send?phone=541164237557&text=%C2%A1Hola,%20necesito%20atención%20de%20cobranzas%20de%20SeguroAuto!%20Ingresé%20desde%20la%20web"
                      target="_blank"
                    >
                      <span>
                        <BsWhatsapp />
                      </span>
                      11-6423-7557
                    </a>
                    <p>|</p>
                    {/* Botón Mail */}
                    <a id="boton-mail" href="mailto:cobranzas@seguroweb.com.ar">
                      <span>
                        <TbMail />
                      </span>
                      cobranzas@seguroweb.com.ar
                    </a>
                  </div>
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </Fade>
        {/* Subtitle Container */}
        <Fade
          delay={100}
          duration={800}
          className="subtitle-container"
        >
          <p>
            Nuestro horario de atención es de 9hs a 18hs todos los días hábiles
          </p>
        </Fade>
        {/* Text Container */}
        <Fade delay={100} duration={800} className="second-text-container">
          <p>
            También podés comunicarte a través de nuestra página de contacto
          </p>
        </Fade>
        {/* Button Container */}
        <Fade delay={100} duration={800} className="boton-contacto-container">
          <Link to="/contacto">Contacto</Link>
        </Fade>
      </section>
    </Container>
  );
}
